import { Banner, BannerStatus, BannerVariant } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { getRouteURL } from "src/common/routing";
import { Route } from "src/components/routing";

interface VerifyYourAccountErrorProps {
  header: React.ReactNode;
  subtitle: React.ReactNode;
}

export const VerifyYourAccountError: React.FC<VerifyYourAccountErrorProps> = ({
  header,
  subtitle,
}) => (
  <Banner
    variant={BannerVariant.Light}
    status={BannerStatus.Warning}
    title={header}
    primaryAction={{
      control: "link",
      children: (
        <Trans id="customer-verify-my-account">Verify my account</Trans>
      ),
      href: getRouteURL({ route: Route.Setup }),
    }}
  >
    {subtitle}
  </Banner>
);
