import {
  MandateResource,
  SchemeIdentifier,
} from "@gocardless/api/dashboard/types";
import {
  Box,
  Color,
  FontWeight,
  Glyph,
  Icon,
  P,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { CalendarDate, DateFormatter } from "@internationalized/date";

export enum PaymentType {
  PAYMENT = 1,
  PLAN,
  SUBSCRIPTION,
}

export interface PaymentsRequireApprovalNoticeProps {
  startDate?: CalendarDate;
  mandate: MandateResource;
  schemeIdentifiers: SchemeIdentifier[];
  paymentType: PaymentType;
}

export const PaymentsRequireApprovalNotice: React.FC<
  PaymentsRequireApprovalNoticeProps
> = ({ startDate, mandate, schemeIdentifiers, paymentType }) => {
  const minimumAdvanceNotice = schemeIdentifiers.find(
    (schemeIdentifer) => schemeIdentifer.scheme === mandate.scheme
  )?.minimum_advance_notice;

  const formattedStartDate = startDate
    ? new DateFormatter("en-US", { dateStyle: "full" }).format(
        startDate.toDate("Europe/London")
      )
    : "";

  const paymentName =
    paymentType === PaymentType.PAYMENT ? "payment" : "subscription";

  return (
    <Box
      bg={Color.White}
      borderRadius={1}
      elevation={4}
      gutterH={1.5}
      gutterV={1.5}
    >
      <Icon name={Glyph.InfoCircle} color={Color.Info_300} size="32px" />
      <Space v={1.5} />
      <P
        color={Color.Brownstone_1400}
        preset={TypePreset.Body_02}
        spaceBelow={1.5}
      >
        We will email the customer to approve this {paymentName}.
      </P>
      <P preset={TypePreset.Body_02} color={Color.Brownstone_1400}>
        To take payment on the{" "}
        <Text weight={FontWeight.Bold}>{formattedStartDate}</Text> we will need
        customer approval at least {minimumAdvanceNotice} working days
        beforehand, otherwise we will take payment {minimumAdvanceNotice}{" "}
        working days after the customer approves.
      </P>
    </Box>
  );
};
