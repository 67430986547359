import { ReactNode } from "react";
import { Field, Label, Option, Select } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { Trans } from "@lingui/macro";
import { MandateResource } from "@gocardless/api/staff/types";

export const fieldName = "mandate";

export interface FormWithMandate {
  mandate: string;
}

export type MandateList = [MandateResource, ...MandateResource[]];

interface MandateFieldProps {
  mandateList: MandateList;
  renderLabel?: () => ReactNode;
  renderOptionText?: (mandate: MandateResource) => string;
  isRequired?: boolean;
  isRestricted?: boolean;
}

export const MandateField: React.FC<MandateFieldProps> = ({
  mandateList,
  renderLabel = () => <Trans id="customer.mandate">Mandate</Trans>,
  renderOptionText = (mandate) => mandate.reference as string,
  isRequired = true,
  isRestricted,
}) => {
  const { register } = useFormContext<FormWithMandate>();

  return (
    <Field>
      <Label htmlFor={fieldName}>{renderLabel()}</Label>
      <Select
        id={fieldName}
        {...register(fieldName, {
          required: isRequired,
        })}
        defaultValue={mandateList[0].id}
        disabled={isRestricted}
      >
        {mandateList.map((mandate) => (
          <Option value={mandate.id} key={mandate.id}>
            {renderOptionText(mandate)}
          </Option>
        ))}
      </Select>
    </Field>
  );
};
