/*
  Currently dates in API request/response bodies are defined as type: string and format: date or date-time
  In UI interfaces generated by the @gocardless/api package, corresponding type is Date
  This is not correct since Date is not a type which can be sent or received in JSON,
  it is always stringified to a string.
  This causes us issues in forms which use request/response types with dates in them,
  such as POSTing to create a payment or a subscription.
  This is a workaround for above problem to cast data to appropriate type before sending or after receiving data
*/

import { CalendarDate } from "@internationalized/date";
import { CalendarDate as FluxCalendarDate } from "@gocardless/flux-react";

export const castStringToDate = (date: string | undefined | null): Date =>
  date as unknown as Date;

export const castDateToString = (date: Date | undefined | null): string =>
  date as unknown as string;

export const parseCalendarDateToString = (
  date: Partial<FluxCalendarDate> | undefined
) => {
  if (!date?.day || !date?.month || !date?.year) {
    return null;
  }

  return new CalendarDate(date.year, date.month, date.day).toString();
};
